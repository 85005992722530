import React, { useRef } from 'react';
import './ContactForm.css';
import emailjs from 'emailjs-com';

const ContactForm = () => {
  const form = useRef();
  const sendEmail  = (event) => {
    event.preventDefault();

    console.log(form.current)

    emailjs.sendForm('service_r7efs2d', 'template_tcykx1e', form.current, '3nmHgr7v2PYb-H6VI')
      .then((result) => {
        alert("Your message was sent succes.")
    }, (error) => {
      alert("There was a problem with our server. Try again later")
    });
  }

  return (
    <div className="contact-container" id="contact">
      <div className="col">
        <h2>Questions?</h2>
        <h2>Ready to start an adventure?</h2>
        <h2>Want to play some Halo?</h2>
        <h2>Contact us!</h2>
      </div>
      <div className="col">
        <form className="contact-form" ref={form}  onSubmit={sendEmail}>
          <input type="text" id="name" name="name" placeholder="Name" required />

          <input type="email" id="email" name="email" placeholder="Email" required />

          <input type="tel" id="phone" name="phone" placeholder="Phone (optional)"/>

          <textarea id="message" name="message" placeholder="Message" required></textarea>

          <button type="submit">Verzenden</button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
