import React from 'react';
import {HeroVideo} from '../components/HeroSection';
import Cards from '../cards/Cards_Home';
import Footer from '../components/Footer';
import Company from './Company';

function Home (){
    return (
        <>
            <HeroVideo
                text="Builders. Creators. Developers. Dreamers."
                src="../videos/video.mp4"
            />
            <Company/>
            <Footer/>
        </>
    )
}

export default Home;