import React from 'react';
import '../css/HeroSection.css';
import '../App.css';
import { Button_Local } from './Button';

function HeroVideo(props){
    return (
        <div className='hero-container'>
            <video className='hero' controls type="video/mp4" src={props.src} autoPlay loop muted>
                Your browser does not support the video tag.
            </video>
            <h1 className='txt-big'>{NewlineText(props.text)}</h1>
        </div>
    )
}

function HeroImage(props){
    return (
        <div className='hero-container'>
            <img className='hero' src={props.src} alt={props.alt}></img>
            <h1 className='txt-normal'>{NewlineText(props.text)}</h1>
            <div className='button-group'>
                <Button_Local 
                    buttonStyle='btn--primary'
                    buttonSize='btn--large'
                    link='/Contact'>
                    GET IN TOUCH
                </Button_Local>
            </div>
        </div>
    )
}

function NewlineText(text) {
    const newText = text.split('\\n').map(str => <p>{str}</p>);
    return newText;
}

export{ HeroImage, HeroVideo}