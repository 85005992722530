import React from 'react'
import Footer from '../components/Footer'
import LocalizedStrings from 'react-localization';
import Cards from '../cards/Cards_People';

import languageSelect from '../util';
import "../css/Page.css"
import "./About.css"

function About() {
    return(
    <>
        <div className='container about-container'>
          <h4>We believe in the power of creativity and collaboration to shape extraordinary experiences. Our company is a vibrant hub of studios, united by a common goal: to foster innovation and ignite the spark of imagination.</h4>
          <p>Drawing inspiration from the boundless realms of design, story, and user experience, we craft products that captivate and leave a lasting impression. Each creation is meticulously crafted with a deep understanding of our users, their desires, and their dreams. We strive to touch their hearts, minds, and souls, forging connections that go beyond entertainment.</p>
          <p>Within our close-knit family of immensely talented individuals, we cherish the value of community and shared purpose. Together, we embark on an exhilarating journey, embracing challenges, and celebrating triumphs as one. It is through this collaborative spirit that we create magic, transcending boundaries and transcending expectations.</p>
          <p>Pandamonium Labs is more than a company; it is a haven for passionate souls who seek to make a difference. We believe in empowering our team to explore new frontiers, take risks, and push the boundaries of what is possible. In our pursuit of excellence, we constantly strive to inspire a sense of wonder and cultivate a lifelong love for our creations.</p>
          <p>Join us on this extraordinary adventure as we unleash the power of imagination, weave captivating narratives, and craft unforgettable experiences. Together, let's shape a world where creativity knows no bounds and where the joy of our products resonates with hearts around the globe. Welcome to the enchanting realm of Pandamonium Labs.</p>
        </div>

        <Cards/>
        <Footer/>
    </>
  )
}

export default About