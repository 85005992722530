import React from "react";
import "./aboutsection.css"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faBrush, faLightbulb } from '@fortawesome/free-solid-svg-icons'

function AboutSection() {
    return(
        <>
            <div id="about">
                <div className="icon-container">
                    <FontAwesomeIcon color="white" size="4x" fixedWidth icon={faGear} />
                </div>
                <div className="about-text">
                    <p>We are SCRIPT, the epitome of innovation and excellence in website and CMS design. Our team, the Smart, Creative, Rapid, and Intelligent Program Team, is dedicated to empowering your digital experiences like never before. By blending artistry with advanced technology, we specialize in crafting visually captivating websites and robust content management systems that enhance your online presence.</p>
                </div>
                <div className="icon-container">
                    <FontAwesomeIcon color="white" size="4x" fixedWidth icon={faBrush} />
                </div>
                <div className="about-text">
                    <p>At SCRIPT, we believe in unleashing the true power of your digital potential. Through seamless integration of captivating visuals, user-friendly interfaces, and cutting-edge functionalities, we bring your vision to life. Our tailored solutions go beyond expectations, allowing us to create a digital masterpiece that truly captivates, engages, and inspires.</p>
                </div>
                <div className="icon-container">
                    <FontAwesomeIcon color="white" size="4x" fixedWidth icon={faLightbulb} />
                </div>
                <div className="about-text">
                    <p>Experience the transformative capabilities of SCRIPT and ignite your digital potential. Join us on this journey and together, let's create an online presence that leaves a lasting impression.</p>
                </div>
            </div>
        </>
    )
}

export default AboutSection;