import React from 'react';


function languageSelect(){
    var userLang = navigator.language || navigator.userLanguage; 
    userLang = (userLang === "nl") ? "nl" : "en";
    
    return 'userLang';
}

export default languageSelect