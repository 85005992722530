import React from "react";

import './Script.css'
import Header from "./proto_components/Header";
import HeroImage from "./proto_components/HeroImage";
import Services from "./proto_components/Services";
import Partners from "./proto_components/Partners";
import ContactForm from "./proto_components/ContactForm";
import AboutSection from "./proto_components/AboutSection";
import ScriptFooter from "./proto_components/ScriptFooter";

function Script(){
    return(
        <>
            <div className="script-container">
                <Header></Header>
                <HeroImage></HeroImage>
                <Services></Services>
                <Partners></Partners>
                <AboutSection></AboutSection>
                <ContactForm></ContactForm>
                <ScriptFooter></ScriptFooter>
            </div>
        </>
    )
}

export default Script