import React from 'react';
import '../css/Company.css'

import {Link} from 'react-router-dom'


function Company (){
    return (
        <>
            <div class="slanted-border"></div>
            <div className="container">
                <div className="column left-column">
                    <h1>The Lab is more than just digital</h1>
                    <p className='text'>Pandamonium Labs is a company that fosters creativity and collaboration among its various studios. With a focus on design, story, and user experience, the Lab is dedicated to creating engaging and memorable products that resonate with users. As a close-knit family of talented individuals, we value community and a shared purpose in their pursuit of excellence.</p>
                </div>
                <div className="column right-column">
                    <div className="image-grid">
                        <a href="https://haydenandme.com" target="_blank" rel="noopener noreferrer">
                            <img src="../images/studios/logo_hayden.png" alt="Hayden&ME logo" />
                        </a>
                        <a href="https://archivesofbalator.com/" target="_blank" rel="noopener noreferrer">
                            <img src="../images/studios/logo_bal.png" alt="Balator logo" />
                        </a>
                        <Link to='/script'>
                            <img src="../images/studios/logo_script_blank.png" alt="script logo" />
                        </Link>
                        {/* <a href="https://pandamoniumlabs.com/script" target="_blank" rel="noopener noreferrer">
                            <img src="../images/studios/logo_script_blank.png" alt="script logo" />
                        </a> */}
                        <a href="https://example.com" target="_blank" rel="noopener noreferrer">
                            <img className='inactive' src="../images/studios/bobo_logo.png" alt="bobo logo" />
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Company;