import React from "react";
import './ScriptFooter.css';

function ScriptFooter() {
    return(
        <>
            <div className="footer-container">
                <p>Powered by Pandamonium Labs | Site design and web development by SCRIPT &copy; 2022 - 2023</p>
            </div>
        </>
    )
}

export default ScriptFooter;