import React, {useState} from 'react'
import { useLocation } from 'react-router-dom';
import '../css/Navbar.css';
import {Link} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'

function Navbar() {
    const location = useLocation();

    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
  return (
    <>
        <nav className={`app ${location.pathname.includes('script') ? 'no-render' : 'navbar'}`}>
            <div className='navbar-container'>
                <Link to="/" className="navbar-logo">
                    PANDAMONIUM LABS
                </Link>
                <div className='menu-icon' onClick={handleClick}>
                    <FontAwesomeIcon color='white' icon={click ? faTimes : faBars} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <Link to='/about' className='nav-links' onClick={closeMobileMenu}>
                            About
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/contact' className='nav-links' onClick={closeMobileMenu}>
                            Contact
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>  
    </>
  )
}

export default Navbar