import {React, useState} from 'react'
import { send } from 'emailjs-com';
import LocalizedStrings from 'react-localization';
import languageSelect from '../util'

let strings = new LocalizedStrings({
  en:{
    project:"Got a question? The Lab would love to help!",
    name:"Name",
    message:"Message",
    contact:"Or contact me on social media!",
    sent:"Your message was sent successfully"
  },
  nl: {
    project:"Heeft u een vraag? Het Lab staat u graag bij!",
    name:"Naam",
    message:"Bericht",
    contact:"Of vind ons op social media!",
    sent:"Uw bericht werd verstuurd"
  }
 });

function Form() {
  strings.setLanguage(languageSelect());


    const [inputs, setInputs] = useState({});


    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setInputs(values => ({...values, [name]: value}))
    }
  
    const handleSubmit = (event) => {
      event.preventDefault();
      
      send(
        'service_qujvo0j',
        'template_0ig40sw',
         inputs,
        '8o_l-Y3LKhcoC8yOS'
      )
        .then((response) => {
            if(response.status === 200){
                alert(strings.sent)
            }else{
                alert('Oops... ' + JSON.stringify(response.text))
            }
        })
        .catch((err) => {
          alert('FAILED...', err);
        });
  
    }
  
    return (
      <>
        <div>
          <div className="row">
              <h4>{strings.project}</h4>
          </div>
          <form onSubmit={handleSubmit}>
          <div className="row input-container">
              <div className="col-xs-12">
                <div className="styled-input wide">
                <input required
                    type="text" 
                    name="name" 
                    value={inputs.name || ""} 
                    onChange={handleChange}
                    />
                  <label>{strings.name}</label> 
                </div>
              </div>
              <div className="col-xs-12">
                <div className="styled-input wide">
                <input required
                    type="email" 
                    name="email" 
                    value={inputs.email || ""} 
                    onChange={handleChange}
                    />
                  <label>Email</label> 
                </div>
              </div>
              <div className="col-xs-12">
                <div className="styled-input wide">
                  <textarea required
                        name="message"
                        value={inputs.message || ""} 
                        onChange={handleChange}
                  ></textarea>
                  <label>{strings.message}</label>
                </div>
              </div>
              <div className="col-xs-12">
                <input type='submit' className="btn-lrg submit-btn" value="Get in touch"/>
              </div>
              <div className="row">
                    <h4>{strings.contact}</h4>
                </div>
          </div>
          </form>
        </div>
      </>
    )
}

export default Form