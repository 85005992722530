import React from 'react';
import { NativeRouter, BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from "./pages/Home";
import Contact from "./pages/Contact";
import About from "./pages/About";

import Script from "./pages/proto/Script";
import Navbar from './components/Navbar';

function App() {
  return (
    <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route exact path="/" element={<Home />}/>
        <Route exact path="/about" element={<About />}/>
        <Route exact path="/contact" element={<Contact />}/>
        <Route exact path="/script" element={<Script />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;