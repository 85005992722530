import React from 'react'
import { Link } from 'react-router-dom'
import '../css/Cards.css';

export const CardItem = ({path, label, text, src}) =>{
  return (
        <li className='cards_item'>
            <Link to={path} className='cards_item_link cards_item_link_row'>
                <figure className='cards_item_pic-wrap' data-category={label}>
                    <img loading='lazy' className='cards_item_img' src={src} alt={label}></img>
                </figure>
                <div className='cards_item_info'>
                    <h5 className='cards_item_text'>{text}</h5>
                </div>
            </Link>
        </li>
  )
}

export const CardItem_Wide = ({subTitle, title, al1, al2, src, link, linkalt}) =>{
    return (
          <li className='cards_item cards_item_wide'>
              <div className='cards_item_link cards_item_link_row'>
                  <div className='cards_item_pic-wrap_wide'>
                      <img loading='lazy' className='cards_item_img_wide' src={src} alt={title}></img>
                  </div>
                  <div className='cards_item_info'>
                      <h3 className='cards_item_title'>{title}</h3>
                      <h5 className='cards_item_title-sub'>{subTitle}</h5>
                      <p className='cards_item_text cards_item_alinea'>{al1}</p>
                      <p className='cards_item_text cards_item_alinea'>{al2}</p>
                      <p className='cards_item_text cards_item_alinea'><a className='cards_item_text' href={link} target="_blank" rel="noreferrer">{linkalt}</a></p>
                  </div>
              </div>
          </li>
    )
  }

export const CardItem_Out = ({path, label, text, src}) =>{
    return (
          <li className='cards_item'>
              <a href={path} target="_blank" rel="noreferrer" className='cards_item_link cards_item_link_column'>
                  <figure className='cards_item_pic-wrap' data-category={label}>
                      <img loading='lazy' className='cards_item_img' src={src} alt={label}></img>
                  </figure>
                  <div className='cards_item_info'>
                      <h5 className='cards_item_text'>{text}</h5>
                  </div>
              </a>
          </li>
    )
}

export const CardItem_Live = ({path, label, text, src}) =>{
    return (
          <li className='cards_item'>
              <a href={path} target="_blank" rel="noreferrer" className='cards_item_link cards_item_link_column'>
                  {/* <figure className='cards_item_pic-wrap' data-category={label}>
                      <img loading='lazy' className='cards_item_img' src={src} alt={label}></img>
                  </figure> */}
                  <div className='cards_item_pic-wrap' data-category={label}>
                    <iframe className='cards_item_img'
                        src={path}
                        scrolling="no"
                        style={{ pointerEvents: 'none', border: "none"}}
                    />
                  </div>
                  <div className='cards_item_info'>
                      <h5 className='cards_item_text'>{text}</h5>
                  </div>
              </a>
          </li>
    )
}