import React from 'react';
import {CardItem} from '../components/CardItem';
import '../css/Cards.css';

import LocalizedStrings from 'react-localization';

import languageSelect from '../util'

let strings = new LocalizedStrings({
    en:{
      csd:"Game Design & Gamefication",
      web:"Professional Website Design",
      supp:"Technological support made to order",
      what:"What the Lab does",
      more:"is more than just digital."
    },
    nl: {
      csd:"Game Design & Gamefication",
      web:"Professioneel Website Design",
      supp:"Technische Hulp waar nodig",
      what:"Wat het Lab doet",
      more:"is meer dan alleen maar digitaal."
    }
   });

function Cards() {
    strings.setLanguage(languageSelect());
  return (
    <div className='cards'>
        <div className='cards_container'>
        <h2>{strings.what}</h2>
            <div className='cards_wrapper'>
                <ul className='cards_items'>
                    <CardItem
                        src="../images/img_2.jpg"
                        text={strings.csd}
                        label='game development'
                        path='/services/software'
                    />
                    <CardItem
                        src="../images/img_1.jpg"
                        text={strings.web}
                        label='website design'
                        path='/services/webdesign'
                    />
                </ul>
            </div>
            <h2>{strings.more}</h2>
            <div className='cards_wrapper'>
                <ul className='cards_items'>
                    <CardItem
                        src="../images/img_4.jpg"
                        text="Resources for Tabletop games"
                        label='Fun for all ages'
                        path='/services/tabletop'
                    />
                    { <CardItem
                        src="../images/img_5.jpg"
                        text="Products for Home and Business"
                        label='Creative Work'
                        path='/services/creative'
                    /> }
                </ul>
            </div>
        </div>
    </div>
  )
}


export default Cards