import React from 'react';
import {CardItem_Wide} from '../components/CardItem';
import '../css/Cards.css';


function Cards() {
    return (
        <div className='cards'>
            <div className='cards_container'>
                <div className='cards_wrapper'>
                    {<ul className='cards_items'>
                        <CardItem_Wide
                            src="../images/people/jory.jpg"
                            title="Jory Jackmaer"
                            subTitle="Founder, Creative Designer & Designated funny man"
                            al1="Hello there. My name is Jory, I'm a software designer, creative renaissance man and all-round lovely chap. I started the lab as a way to express myself and have a place to showcase my work. 
                            My goal is to be creative, build nice things and have some fun in the progress."
                            al2="I'm more of the strong silent type so you wont find me often conversing around the watercooler, though I'm always up for an evening of boardgames. I like to create stories and try to keep that theme going throughout my work."
                        />
                    </ul>
                    }
                </div>
            </div>
        </div>
      )
};

export default Cards