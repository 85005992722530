import React from 'react'
import Form from '../components/Form'
import Footer from '../components/Footer'
import '../css/Form.css'

function Contact() {
  return(
    <>
      <Form/>
      <Footer/>
    </>
  )
}

export default Contact