import React from 'react';
import './Services.css';

const Services = () => {
  return (
    <div id="services" className="services-container">
      <h2>Our Building Blocks for a Great Design</h2>
      <div className="services-grid">
        <div className="service">
          <h3>Web Design and Development: </h3>
          <p>Create a Captivating Online Presence.</p>
          <p className='fly-out'> Our expert team of web designers and developers specializes in crafting visually stunning and user-friendly websites that truly reflect your brand identity. With a deep understanding of your target audience, we tailor each website to captivate your visitors and leave a lasting impression. From seamless navigation to engaging visuals, we ensure your online presence stands out from the competition.</p>
        </div>
        <div className="service">
          <h3>E-commerce Solutions: </h3>
          <p>Build Your Online Store with Confidence.</p>
          <p className='fly-out'>Take your business to the next level with our comprehensive e-commerce solutions. Our experienced team will guide you through the process of building a robust online store that showcases your products or services in an enticing manner. By integrating secure payment gateways, we enable seamless transactions and provide your customers with a safe and convenient shopping experience.</p>
        </div>
        <div className="service">
          <h3>Responsive Design: </h3>
          <p>Engage Customers on Any Device.</p>
          <p className='fly-out'>In today's mobile-centric world, having a responsive website is essential. We specialize in creating designs that adapt seamlessly to various screen sizes, including desktops, tablets, and mobile phones. By prioritizing user experience across all devices, we ensure your website looks and functions flawlessly, maximizing customer engagement and conversions.</p>
        </div>
        <div className="service">
          <h3>Search Engine Optimization (SEO): </h3>
          <p>Boost Your Online Visibility.</p>
          <p className='fly-out'>Improve your website's visibility and drive organic traffic with our expert SEO services. Our team employs proven strategies to optimize your website, ensuring it ranks higher in search engine results. By targeting relevant keywords, optimizing content, and enhancing website performance, we help you reach your target audience and increase your online presence.</p>
        </div>
        <div className="service">
          <h3>Content Creation: </h3>
          <p>Engage and Inspire Your Audience.</p>
          <p className='fly-out'>Compelling and engaging content is the key to capturing your audience's attention. Our skilled content creators specialize in crafting captivating copy, blog posts, and multimedia elements that resonate with your target market. By delivering valuable and informative content, we help you establish authority, build brand loyalty, and keep visitors coming back for more.</p>
        </div>
        <div className="service">
          <h3>UX/UI Design: </h3>
          <p>Enhance User Experience and Interface.</p>
          <p className='fly-out'>Deliver an exceptional user experience with our UX/UI design expertise. Our designers work closely with you to understand your users' needs, creating intuitive interfaces that are easy to navigate. By focusing on usability, accessibility, and user satisfaction, we enhance your website's overall performance and leave a lasting positive impression.</p>
        </div>
        <div className="service">
          <h3>Social Media Integration: </h3>
          <p>Amplify Your Online Presence.</p>
          <p className='fly-out'>Leverage the power of social media to expand your brand's reach and increase awareness. Our seamless integration of social media platforms into your website allows you to engage with your audience, promote your content, and drive traffic. By leveraging social media marketing, you can strengthen your online presence and connect with your target market on a deeper level.</p>
        </div>
        <div className="service">
          <h3>Analytics and Reporting: </h3>
          <p>Harness the Power of Data.</p>
          <p className='fly-out'>Make informed business decisions with our comprehensive analytics and reporting services. We implement cutting-edge tracking and analytics tools to gather valuable insights on website performance, user behavior, and conversion rates. By analyzing this data, we help you identify strengths, uncover opportunities, and optimize your online strategies for maximum impact.</p>
        </div>
        <div className="service">
          <h3>Digital Marketing: </h3>
          <p>Expand Your Reach and Drive Conversions.</p>
          <p className='fly-out'>Our digital marketing experts are here to help you grow your business online. From email marketing to paid advertising campaigns and social media marketing, we develop and execute tailored strategies to drive targeted traffic and boost conversions. With our comprehensive approach, we ensure your brand stays top-of-mind and achieves maximum visibility in the digital landscape.</p>
        </div>
        <div className="service">
          <h3>Custom Application Development: </h3>
          <p>Streamline Your Business Processes.</p>
          <p className='fly-out'>Maximize your business efficiency with our custom application development services. Whether you need a web application or software solution, our experienced developers create tailored solutions that streamline your unique business processes. From automating tasks to improving communication, we empower your business with the tools it needs to thrive.</p>
        </div>
        <div className="service">
          <h3>Website Maintenance and Support: </h3>
          <p>Keep Your Website Running Smoothly.</p>
          <p className='fly-out'>We understand that a well-maintained website is crucial for your business's success. Our dedicated team provides ongoing support, regular updates, and maintenance services to ensure your website functions smoothly and remains up to date. With our reliable services, you can focus on your core business while we take care of the technical aspects.</p>
        </div>
        <div className="service">
          <h3>Hosting and Domain Management: </h3>
          <p>Reliable and Secure Online Presence.</p>
          <p className='fly-out'>Ensure your website is accessible and secure with our reliable hosting and domain management services. We handle the technical aspects of hosting, providing a stable and fast infrastructure that keeps your website running smoothly. With our domain management expertise, we take care of registrations and maintenance, allowing you to focus on growing your online presence with peace of mind.




</p>
        </div>
      </div>
    </div>
  );
};

export default Services;