import React from "react";
import './HeroImage.css'
import {Link as ScrollLink, animateScroll as scroll } from 'react-scroll';

function HeroImage(){
    const scrollToSection = (sectionId) => {
          scroll.scrollTo(sectionId, {
            smooth: true,
            offset: -100,
          });
      }


    return(
        <>
            <div className="heroimage_container">
                <div className="herotext">
                    <h1>Welcome to SCRIPT, Where Creativity and Intelligence Converge</h1>
                    <p>Contact us now and embark on a transformative journey with SCRIPT. Elevate your online presence, captivate your audience, and embrace the limitless possibilities of the digital landscape.</p>
                    <p>Are you ready to make your mark?</p>
                    <div className="button-container">
                        <button className="call-button">
                            <ScrollLink
                                to="contact"
                                smooth={true}
                                // offset={-50}
                                onClick={() => scrollToSection("contact")}
                            >
                                Let's create something extraordinary together.
                            </ScrollLink>
                        </button>
                    </div>
                </div>
                <div className="heroimage">
                    {/* <img src="./images/path2742.png"></img> */}
                </div>
            </div>
        </>
    )
}

export default HeroImage;