import React, { useState, useEffect } from 'react';
import '../css/Footer.css';
 
import LocalizedStrings from 'react-localization';
import languageSelect from '../util'


let strings = new LocalizedStrings({
    en:{
      about:"About",
      how:"How it works"
    },
    nl: {
      about:"Over",
      how:"Hoe het werkt"
    }
   });

function Footer() {
  const [currentYear] = useState(new Date().getFullYear());
  strings.setLanguage(languageSelect());

    return (
    <div className='footer-container'>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Social</h2>
            <a href="https://twitter.com/PandamoniumLabs" target="_blank" rel="noreferrer">Twitter</a>
            <a href="https://www.facebook.com/PandamoniumLabs" target="_blank" rel="noreferrer">Facebook</a>
            <a href="https://www.instagram.com/pandamoniumlabs/" target="_blank" rel="noreferrer">Instagram</a>
            <a href="https://www.youtube.com/channel/UCCxXuN8r-siMn2DYmjnqh1g" target="_blank" rel="noreferrer">Youtube</a>
            {/* <a href="https://www.buymeacoffee.com/pandamoniumlabs" target="_blank" rel="noreferrer">Coffee</a> */}
          </div>
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <small className='website-rights'>PANDAMONIUM LABS © 2022 - {currentYear} | website design & development by SCRIPT </small>
        </div>
      </section>
    </div>
  )
}

export default Footer