import React from "react";
import { CardItem_Out } from "../../../components/CardItem";
import '../../../css/Cards.css';
import './Partners.css'

function Partners(){
    return(
        <>
            <div className='cards' id="partners">
                <div className='cards_container'>
                <h2>Our Partners</h2>
                    <div className='cards_wrapper partner_wrapper'>          
                        <ul className='cards_items'>
                            {<CardItem_Out
                                src="../images/proto/partners/foodtruck.png"
                                label='foodtruckallinone'
                                path='https://www.foodtruckallinone.be'
                            />}
                            {<CardItem_Out
                                src="../images/proto/partners/hayden.png"
                                label='Hayden&Me'
                                path='https://www.haydenandme.com'
                            />}
                            {<CardItem_Out
                                src="../images/proto/partners/panda.png"
                                label='Pandamonium Labs'
                                path='https://www.pandamoniumlabs.com'
                            />}
                            {<CardItem_Out
                                src="../images/proto/partners/archive.png"
                                label='Archives of Balator'
                                path='https://www.archivesofbalator.com'
                            />}
                            {/* {<CardItem_Out
                                src="../images/proto/partners/bobo.png"
                                label='Bobo Games'
                                path='https://www.pandamoniumlabs.com/#/bobogames'
                            />} */}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Partners;