import React, {useState} from 'react';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import {Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Header.css';

import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'

function Header() {
  const scrollToSection = (sectionId) => {
    closeMobileMenu();

    scroll.scrollTo(sectionId, {
      smooth: true,
      offset: -100,
    });
  }

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <header className="header-container">
      <div className="logo-container">
        <img src={'./images/logo_white.png'} alt="" className="logo" />
      </div>
      <div className='menu-icon' onClick={handleClick}>
        <FontAwesomeIcon color='white' size='2x' icon={click ? faTimes : faBars} />
      </div>
      <p className='tag'>Smart, Creative, Rapid & Intelligent Program Team</p>
      <nav className={click ? 'nav-menu active' : 'nav-menu'}>     
        <ul>
          <li><ScrollLink
              to="services"
              smooth={true}
              // offset={-100}
              onClick={() => scrollToSection("services")}
            >Services</ScrollLink></li>
          <li><ScrollLink
              to="partners"
              smooth={true}
              // offset={-100}
              onClick={() => scrollToSection("partners")}
            >Partners</ScrollLink></li>
          <li><ScrollLink
              to="about"
              smooth={true}
              // offset={-100}
              onClick={() => scrollToSection("about")}
            >About</ScrollLink></li>
          <li><ScrollLink
              to="contact"
              smooth={true}
              // offset={-100}
              onClick={() => scrollToSection("contact")}
            >Contact</ScrollLink></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;